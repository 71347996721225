<template>
    <div class="mmv-header" :class="showFixed">
        <div class="mmv-header-inner">
            <div class="mmv-header-logo" @click="onClickLogo">
                <img class="mmv-header-logo-img" draggable="false" src="@/assets/log.png" alt="logo">
            </div>
            <div class="mmv-header-nav-item" v-for="(item, index) in routes" :key="index">
                <router-link    draggable="false" exact :to="item">{{ item.meta.title }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MmvHeader',
    data() {
        return {
            isFixed: false,
            scrollTopBuffer: 0,
        }
    },
    computed: {
        routes() {
            return this.$router.getRoutes().filter(route => route.meta.isNav);
        },
        showFixed() {
            if (this.scrollTopBuffer > 450) {
                if (this.$route.meta.alwaysShowHeader) {
                    return 'fixed-show';
                }
                return this.isFixed ? 'fixed-show' : 'fixed-hide';
            }
            return '';
        },
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll() {
            
            const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollTop > 450) {
                this.isFixed = true;
            } else {
                this.isFixed = false;
            }
            if (this.scrollTopBuffer > scrollTop) {
                this.isFixed = false;
            }
            this.scrollTopBuffer = scrollTop;
        },
        onClickLogo() {
            this.$router.push({ path: '/' });
        },
    },
}
</script>

<style lang="scss" scoped>
.mmv-header {
    width: 100%;
    position: absolute;
    z-index: 999;
    user-select: none;
    &.fixed-hide {
        position: fixed;
        background: rgba($color: #000000, $alpha: 0.7);
        animation: hideFixed 0.5s forwards;
    }
    &.fixed-show {
        position: fixed;
        background: rgba($color: #000000, $alpha: 0.7);
        animation: showFixed 0.5s forwards;
    }
}
.mmv-header-inner {
    width: 1200px;
    line-height: 100px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mmv-header-logo {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.mmv-header-nav-item {
    a {
        height: 100%;
        display: block;
        padding: 0 30px;
        color: #fff;
        font-size: 20px;
        text-decoration: none;
    }
    .router-link-exact-active,
    .router-link-active {
        color: #dcb17c;
        font-weight: bold;
    }
    :hover {
        color: #dcb17c;
    }
}

@keyframes showFixed {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}

@keyframes hideFixed {
    from {
        top: 0;
    }
    to {
        top: -100px;
    }
}
</style>