import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import '@/directives/check-footer';

import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import { Button, Image, Message } from "element-ui";

import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video;

Vue.prototype.$message = Message;

Vue.use(Image);
Vue.use(Button);
Vue.use(ElementUI);

Vue.prototype.$scrollTo = (to = 0, behavior = 'smooth') => {
    window.scroll({
        top: to,
        behavior: behavior || 'auto',
    })
}

/**
 * 获取dom元素所在页面的Y轴位置
 * @param {HTMLElement} element dom元素
 */
 Vue.prototype.$getElementTop = (element) => {
    let actualTop = element.offsetTop;
    let current = element.offsetParent;
    while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
    }
    return actualTop;
};

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
