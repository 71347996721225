import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "pageIndex",
        component: () => import("@/pages/pageIndex/pageIndex"),
        meta: { title: '首页', isNav: true },
    },
    {
        path: "/productService",
        name: "productService",
        component: () => import("@/pages/productService/productService"),
        meta: { title: '产品服务', isNav: true },
    },
    {
        path: "/solutionCom",
        name: "solutionCom",
        component: () => import("@/pages/solutionCom/solutionCom"),
        meta: { title: '解决方案', isNav: true },
    },
    {
        path: "/customerStories",
        name: "customerStories",
        component: () => import("@/pages/customerStories/customerStories"),
        meta: { title: '客户案例', isNav: true, alwaysShowHeader: true },
    },
    {
        path: "/aboutMe",
        name: "aboutMe",
        component: () => import("@/pages/aboutMe/aboutMe"),
        meta: { title: '关于我们', isNav: true },
    },
    {
        path: "/drawingDoard",
        name: "drawingDoard ",
        component: () => import("@/pages/drawingDoard/drawingDoard"),
        meta: { title: '案例详情', },
    },
];

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

export default router;
