<template>
    <div class="mmv-rocket" v-check-footer="50">
        <div :class="{ 'mmv-rocket__rocket--visible': rocketVisible }" @click="scrollToTop" class="mmv-rocket__rocket">
            <i class="mmv-rocket__icon-r"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mmv-roucket',
    props: {
        to: {
            type: Number,
            default: 0
        },
        distance: {
            type: Number,
            default: 1000
        }
    },
    data() {
        return {
            rocketVisible: false,
            qrcodeVisible: false
        }
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll)
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll)
    },
    methods: {
        scrollToTop() {
            this.$scrollTo(this.to)
        },
        onScroll() {
            var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollTop <= this.distance && this.rocketVisible === true) {
                this.rocketVisible = false;
            } else if (scrollTop > this.distance && this.rocketVisible === false) {
                this.rocketVisible = true;
            }
        }
    }
}
</script>

<style lang="scss">
.mmv-rocket {
    position: fixed;
    bottom: 200px;
    left: 50%;
    margin-left: 700px;
    @media (max-width: 1244px) {
        margin-left: 0;
        left: auto;
        right: 0;
    }
    &__rocket {
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.2s;
        transition-property: opacity, visibility;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        &--visible {
            opacity: 1;
            visibility: visible;
        }
    }
    &__icon {
        &-r {
            display: block;
            width: 50px;
            height: 50px;
            background-position: 50%;
            background-repeat: no-repeat;
            background-image: url("@/assets/rocket.png");
            background-size: cover;
        }
    }
}
</style>
