import Vue from 'vue';

function getComputedStyle(t, e) {
    return window.getComputedStyle ? window.getComputedStyle(t)[e] : ""
}

function toInt(t) {
    return parseInt(getComputedStyle(t, "bottom"), 10) || 0
}

Vue.directive("check-footer", {
    bind(el, binding) {
        el.vueCheckFooter = {
            margin: binding.value || 0,
            origBottom: toInt(el),
            handler() {
                var e = document.documentElement.clientHeight || document.body.clientHeight;
                var n = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                var o = el.vueCheckFooter;
                var footer = o.footer;
                var r = o.margin;
                var c = o.origBottom;
                if (footer) {
                    var l = e - (footer.offsetTop - n);
                    if (l + r > c) {
                        el.style.transitionDuration = ""
                        el.style.transitionProperty = ""
                        el.style.top = "auto"
                        el.style.bottom = "".concat(l + r, "px");
                    } else if (el.style.bottom) {
                        var d = getComputedStyle(el, "transition-property");
                        el.style.transitionDuration = "0.2s"
                        el.style.transitionProperty = d ? "".concat(d, ", bottom") : ""
                        el.style.top = ""
                        el.style.bottom = ""
                    }
                }
            }
        }
    },
    inserted(el) {
        var footer = document.querySelector(".mmv-footer");
        if (footer) {
            el.vueCheckFooter.footer = footer
            el.vueCheckFooter.origBottom = toInt(el)
            document.addEventListener("scroll", el.vueCheckFooter.handler)
        }
    },
    update(el, binding) {
        if ("function" == typeof binding.value) {
            el.vueCheckFooter.margin = binding.value || 0;
        }
    },
    unbind(el) {
        document.removeEventListener("scroll", el.vueCheckFooter.handler);
        delete el.vueCheckFooter;
    }
})
