<template>
    <div>
        <MmvHeader></MmvHeader>
        <RouterView></RouterView>
        <MmvFooter></MmvFooter>
        <MmvRocket :distance="100"></MmvRocket>
    </div>
</template>

<script>
import MmvHeader from '@/components/mmv-header.vue';
import MmvFooter from '@/components/mmv-footer.vue';
import MmvRocket from '@/components/mmv-rocket.vue';

export default {
    name: "App",
    components: {
        MmvHeader,
        MmvFooter,
        MmvRocket,
    },
};
</script>
